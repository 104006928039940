<template>
  <div class="py-15" style="background: #eeeeee">
    <v-container>
      <v-row class="ml-2 ml-sm-0">
        <v-col class="col-6">
          <v-row class="text-color-orange font-36 font-weight-500"> 02 </v-row>
          <v-row
            class="text-color-black font-36 align-center font-weight-600 position-relative title-row"
          >
            <div class="f-title">Unsere Projekte</div>
            <div class="sec-title">Unsere Projekte</div>
          </v-row>
        </v-col>
      </v-row>
    </v-container>
    <div class="wrapper-slider">
      <!-- START SWIPER LARGE SCREENS -->
      <swiper
        class="d-none d-md-block"
        :style="{
          '--swiper-navigation-color': 'transparent',
        }"
        :options="swiperOption"
      >
        <swiper-slide
          v-for="(project, i) in projects.slice(0, 8)"
          :key="project.id"
          class="wrapper-images pa-0"
        >
          <div>
            <div class="anim-image">
              <img
                :src="
                  require('@/assets/images/projects/' +
                    project.folder +
                    '/' +
                    project.images[0])
                "
              />
            </div>

            <figcaption class="d-context text-color-white font-24">
              <div class="id-num">0{{ i + 1 }}</div>
              <div class="text-uppercase">{{ project.title }}</div>
              <v-divider color="#F39300" class="line mt-2" />
            </figcaption>
            <figcaption class="h-content">
              <v-row no-gutters class="mt-3 align-center">
                <div>
                  <img src="@/assets/images/gebiet-icon.svg" />
                </div>
                <div class="ml-3">
                  <div class="text-color-white">Gebiet:</div>
                  <div class="text-color-orange">
                    {{ project.square }} m<sup>2</sup>
                  </div>
                </div>
              </v-row>
              <v-row no-gutters class="mt-4 align-center">
                <div>
                  <img src="@/assets/images/kapazitat-icon.svg" />
                </div>
                <div class="ml-3">
                  <div class="text-color-white">Kapazität:</div>
                  <div class="text-color-orange">{{ project.capacity }}</div>
                </div>
              </v-row>
            </figcaption>
            <figcaption class="h-btn">
              <v-btn
                @click="$router.push(`/project/${project.id}`)"
                class="btn-anim text-transform-none"
                outlined
              >
                <div class="icon">
                  <v-icon color="#f2f2f2">mdi-arrow-right</v-icon>
                </div>
                <div class="content-text">Mehr Lesen</div>
              </v-btn>
            </figcaption>
          </div>
        </swiper-slide>
        <div class="swiper-button-prev" slot="button-prev"></div>
        <div class="swiper-button-next" slot="button-next"></div>
      </swiper>
      <!-- END SWIPER LARGE SCREENS -->

      <!-- START SWIPER MOBILE SCREENS -->
      <swiper
        :style="{
          '--swiper-navigation-color': 'transparent',
        }"
        :options="swiperOptionMobile"
        class="d-block mt-7 d-md-none"
      >
        <swiper-slide
          v-for="(project, i) in projects.slice(0, 8)"
          :key="project.id"
          class="wrapper-images col-6 pa-0"
        >
          <div class="">
            <div class="anim-image">
              <img
                :src="
                  require('@/assets/images/projects/' +
                    project.folder +
                    '/' +
                    project.images[0])
                "
              />
            </div>
            <figcaption class="d-context text-color-white font-24">
              <div class="id-num">0{{ i + 1 }}</div>
              <div class="text-uppercase">{{ project.title }}</div>
              <v-divider color="#F39300" class="line mt-2" />
            </figcaption>
            <figcaption class="h-content">
              <v-row no-gutters class="mt-3 align-center">
                <div>
                  <img src="@/assets/images/gebiet-icon.svg" />
                </div>
                <div class="ml-3">
                  <div class="text-color-white">Gebiet:</div>
                  <div class="text-color-orange">
                    {{ project.square }} m<sup>2</sup>
                  </div>
                </div>
              </v-row>
              <v-row no-gutters class="mt-4 align-center">
                <div>
                  <img src="@/assets/images/kapazitat-icon.svg" />
                </div>
                <div class="ml-3">
                  <div class="text-color-white">Kapazität:</div>
                  <div class="text-color-orange">{{ project.capacity }}</div>
                </div>
              </v-row>
            </figcaption>
            <figcaption class="h-btn">
              <v-btn
                @click="$router.push(`/project/${project.id}`)"
                class="btn-anim text-transform-none"
                :class="{ 'btn-anim-mobile': $vuetify.breakpoint.smAndDown }"
                outlined
              >
                <div class="icon">
                  <v-icon color="#f2f2f2">mdi-arrow-right</v-icon>
                </div>
                <div class="content-text">Mehr Lesen</div>
              </v-btn>
            </figcaption>
          </div>
        </swiper-slide>
        <div class="swiper-button-prev" slot="button-prev"></div>
        <div class="swiper-button-next" slot="button-next"></div>
      </swiper>
      <!-- END SWIPER MOBILE SCREENS -->
    </div>
    <v-row justify="center" class="mt-8">
      <v-btn
        @click="$router.push({ name: 'all' })"
        class="btn-anim-to-all text-transform-none"
        outlined
      >
        <div class="icon">
          <v-icon color="#f2f2f2">mdi-arrow-right</v-icon>
        </div>
        <div class="content-text">Alle Projekte</div>
      </v-btn>
    </v-row>
  </div>
</template>

<script>
import { Swiper, SwiperSlide } from "vue-awesome-swiper";
import "swiper/css/swiper.css";
import ProjectsMixin from "@/mixins/ProjectsMixin.js";

export default {
  components: {
    Swiper,
    SwiperSlide,
  },
  mixins: [ProjectsMixin],
  data: () => ({
    swiperOption: {
      slidesPerView: 4,
      spaceBetween: 0,
      slidesPerGroup: 4,
      initialSlide: 0,
      loop: true,
      loopFillGroupWithBlank: false,
      navigation: {
        nextEl: ".swiper-button-next",
        prevEl: ".swiper-button-prev",
      },
    },
    swiperOptionMobile: {
      slidesPerView: 2,
      spaceBetween: 0,
      slidesPerGroup: 2,
      loop: true,
      loopFillGroupWithBlank: false,
      navigation: {
        nextEl: ".swiper-button-next",
        prevEl: ".swiper-button-prev",
      },
    },
  }),
};
</script>

<style scoped lang="scss">
.wrapper-images {
  position: relative !important;
  overflow: hidden;
  height: 620px;
  &:hover .h-content {
    left: 20px;
  }
  &:hover .line {
    width: 100px !important;
    border: 0.5px solid #f39300;
  }
  &:hover .id-num {
    color: #f39300;
  }
  &:hover .anim-image {
    scale: 1.2;
    &::after {
      background: linear-gradient(0deg, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7));
    }
  }
  &:hover .h-btn {
    bottom: 120px;
  }
}

.wrapper-slider {
  &:hover .swiper-button-prev {
    opacity: 0.4;
  }
  &:hover .swiper-button-next {
    opacity: 0.4;
  }
}
@media screen and (max-width: 968px) {
  .anim-image {
    height: 400px !important;
    img {
      object-fit: cover;
      height: 400px !important;
      width: 100% !important;
    }
  }
  .wrapper-images {
    height: 400px;
    &:hover .h-btn {
      bottom: 70px;
    }
  }
  .btn-anim {
    width: 152px !important;
    font-weight: 400 !important;
    font-size: 16px !important;
  }
}
.anim-image {
  height: 620px;
  position: relative;
  width: 100% !important;
  object-fit: cover;
  transition: all 0.3s ease-out;
  display: inline-block;
  img {
    object-fit: cover;
    height: 620px;
    width: 100% !important;
    background: linear-gradient(-90deg, #ffffff, #8a8a8a);
    background-size: 400% 400%;
    animation: gradient 15s ease 10s;
  }
  &::after {
    position: absolute;
    content: "";
    z-index: 2;
    left: 0px;
    top: 0px;
    width: 100%;
    height: 100%;
    transition: all 0.3s ease-out;
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5));
  }
}
.d-context {
  position: absolute;
  z-index: 3;
  top: 30px;
  left: 20px;
  .line {
    width: 0px;
    transition: all 0.3s ease-out;
  }
  .id-num {
    transition: all 0.3s ease-out;
  }
}
.h-content {
  position: absolute;
  z-index: 3;
  top: 120px;
  left: -200px;
  transition: all 0.3s ease-out;
}
.h-btn {
  position: absolute;
  bottom: -60px;
  z-index: 3;
  left: calc(50% - 96px);
  transition: all 0.3s ease-out;
}
.btn-anim {
  width: 192px;
  height: 48px !important;
  background: rgba(242, 242, 242, 0.1);
  border: 2px solid #f2f2f2;
  border-radius: 5px;
  font-weight: 400;
  font-size: 20px;
  line-height: 28px;
  color: #f2f2f2;
  overflow: hidden;
  transition: all 0.2s ease-out;
  &-mobile {
    width: 142px;
  }

  .icon {
    margin-left: -100px;
    transition: all 0.2s ease-out 0s;
  }
  .content-text {
    margin-left: 60px;
    transition: all 0.2s ease-out 0.1s;
  }
  &:hover {
    background: linear-gradient(180deg, #1b1b1b 0%, #1b1b1b 200%), #f2f2f2;
    border: 2px solid #1b1b1b;
  }
  &:hover .icon {
    margin-left: 175px;
    transition: all 0.2s ease-out 0.1s;
  }
  &:hover .content-text {
    margin-top: 100px;
    transition: all 0.2s ease-out 0s;
  }
}

.swiper-button-prev {
  width: 40px;
  height: 40px;
  background: rgba(217, 217, 217, 0.15);
  border: 2px solid #efefef;
  backdrop-filter: blur(2px);
  border-radius: 1px;
  background-image: url("../../assets/images/swiper-arrow-left.svg") !important;
  background-repeat: no-repeat;
  background-size: 70% 70%;
  background-position: center;
  opacity: 0;
  transition: all 0.3s ease-out;
  &:hover {
    opacity: 1 !important;
  }
}

.swiper-button-next {
  width: 40px;
  height: 40px;
  background: rgba(217, 217, 217, 0.15);
  border: 2px solid #efefef;
  backdrop-filter: blur(2px);
  border-radius: 1px;
  background-image: url("../../assets/images/swiper-arrow-right.svg") !important;
  background-repeat: no-repeat;
  background-size: 70% 70%;
  background-position: center;
  opacity: 0;
  transition: all 0.3s ease-out;
  &:hover {
    opacity: 1 !important;
  }
}

.btn-anim-to-all {
  width: 260px !important;
  height: 48px !important;
  background: #f2f2f2;
  border: 2px solid #1b1b1b;
  border-radius: 5px;
  font-weight: 500;
  font-size: 20px;
  line-height: 20px;
  color: #1b1b1b;
  overflow: hidden;
  transition: all 0.2s ease-out;

  .icon {
    margin-left: -60px;
    transition: all 0.2s ease-out 0s;
  }
  .content-text {
    margin-left: 30px;
    transition: all 0.2s ease-out 0.1s;
  }
  &:hover {
    background: #1b1b1b;
  }
  &:hover .icon {
    margin-left: 165px;
    transition: all 0.2s ease-out 0.1s;
  }
  &:hover .content-text {
    margin-top: 100px;
    transition: all 0.2s ease-out 0s;
  }
}

@keyframes gradient {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}
</style>